import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Status from '../status'
import {
    fetchSupportFulfilled,
    fetchSupportLoading,
    fetchSupportRejected,
    fetchMoreSupportFulfilled,
    fetchMoreSupportRejected,
    fetchTicketFulfilled,
    fetchTicketRejected,
    sendTicketMessageFulfilled,
    sendTicketMessageLoading,
    sendTicketMessageRejected,
    setTicketStatusFulfilled,
    setTicketStatusRejected,
    setShowCloseAction,
} from './support.actions'
import FunctionsService from '../../services/FunctionsService'

export const fetchTicket = createAsyncThunk('support/fetchTicket', async ({ tid }, { getState }) => {
    const tickets = getState().support.tickets
    const curr = tickets.find(t => t.id === tid)
    if (curr === undefined) {
        return FunctionsService.getSupportTicket(tid)
    } else {
        return curr
    }
})

export const fetchSupport = createAsyncThunk('support/fetchSupport', async (_, { getState }) => {
    const showClose = getState().support.showClose
    const tickets = await FunctionsService.getSupportTickets(undefined, showClose)
    for (let i = 0; i < tickets.length; i++) {
        tickets[i].isPremium = await FunctionsService.isCoddyUserPremium(tickets[i].uid)
    }
    return tickets;
})

export const fetchMoreSupport = createAsyncThunk('support/fetchMoreSupport', async (_, { getState }) => {
    const tickets = getState().support.tickets
    const showClose = getState().support.showClose
    const newTickets = await FunctionsService.getSupportTickets(tickets[tickets.length - 1]?.lastUpdated, showClose)
    for (let i = 0; i < newTickets.length; i++) {
        newTickets[i].isPremium = await FunctionsService.isCoddyUserPremium(newTickets[i].uid)
    }
    return newTickets;
})

export const sendTicketMessage = createAsyncThunk('support/sendTicketMessage', async ({ tid, message, noMail }, { getState }) => {
    const uid = getState().user.data.connectedUid
    return FunctionsService.sendSupportTicketMessage(tid, message, uid, noMail)
})

export const setTicketStatus = createAsyncThunk('support/setTicketStatus', async ({ tid, status }) => {
    return FunctionsService.setSupportTicketStatus(tid, status)
})

export const supportSlice = createSlice({
    name: 'support',
    initialState: {
        tickets: [],
        currTicket: {},
        fetchStatus: Status.None,
        sendMessageStatus: Status.None,
        showClose: false,
    },
    reducers: {
        setShowClose: setShowCloseAction,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSupport.pending, fetchSupportLoading)
            .addCase(fetchSupport.fulfilled, fetchSupportFulfilled)
            .addCase(fetchSupport.rejected, fetchSupportRejected)
            .addCase(fetchMoreSupport.fulfilled, fetchMoreSupportFulfilled)
            .addCase(fetchMoreSupport.rejected, fetchMoreSupportRejected)
            .addCase(fetchTicket.fulfilled, fetchTicketFulfilled)
            .addCase(fetchTicket.rejected, fetchTicketRejected)
            .addCase(sendTicketMessage.pending, sendTicketMessageLoading)
            .addCase(sendTicketMessage.fulfilled, sendTicketMessageFulfilled)
            .addCase(sendTicketMessage.rejected, sendTicketMessageRejected)
            .addCase(setTicketStatus.fulfilled, setTicketStatusFulfilled)
            .addCase(setTicketStatus.rejected, setTicketStatusRejected)
    },
})

export const {
    setShowClose,
} = supportSlice.actions

export default supportSlice.reducer